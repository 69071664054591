$default-font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
Roboto, "Helvetica Neue", Arial, sans-serif, FontAwesome;
//$default-font-family: Arial, sans-serif, FontAwesome;

$sidebar-width: 180px;
$header-height: 44px;

// Font-sizes
$font-size-xl: 24px;
$font-size-lg: 21px;
$font-size-md: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;

// Font-weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;

// Animations
$animation-timing-sm: 0.2s;
$animation-timing-md: 0.33s;
$animation-timing-lg: 0.7s;
$animation-timing-fn: cubic-bezier(0.17, 0.67, 0.83, 0.67);

// Opacity values
$dark-opacity-primary: 0.87;
$dark-opacity-secondary: 0.54;
$dark-opacity-tertiary: 0.38;
$light-opacity-primary: 1;
$light-opacity-secondary: 0.7;
$light-opacity-tertiary: 0.5;

// Colors
// rgba to rgb - http://marcodiiga.github.io/rgba-to-rgb-conversion
$black: #000000;
//$black-primary: rgba(0, 0, 0, $dark-opacity-primary);
$black-primary: rgb(33, 33, 33);
$black-secondary: rgb(117, 117, 117);
$black-tertiary: rgb(158, 158, 158);
$white-primary: rgba(255, 255, 255, $light-opacity-primary);
$white-secondary: rgba(255, 255, 255, $light-opacity-secondary);
$white-tertiary: rgba(255, 255, 255, $light-opacity-tertiary);

$gray6: rgb(237, 237, 237); // $dark-opacity-tertiary / 6
$gray3: rgb(222, 222, 222); // $dark-opacity-tertiary / 3
$gray2: rgb(201, 201, 201); // $dark-opacity-tertiary / 2

$page-secondary-background-color: #f0f0f0;

$link-color: #007bff;
$link-hover-color: #0056b3;
$primary-color: #2f80ed;
//$subprimary-color: rgba(47, 128, 237, $dark-opacity-tertiary);
$subprimary-color: rgb(151, 192, 246);

$facebook-color: #3b5998;
$youtube-color: #ff0000;
$instagram-color: #e1306c;
$twitter-color: #1da1f2;
$vk-color: #45668e;
$google-color: #dd4b39;
$telegram-color: #0088cc;
$odnoklassniki-color: #ed812b;
$gold-color: rgb(255,164,0);

$error-color: #d0021b;
$info-color: #4a90e2;
$light-info-color: #7db1ff;
$success-color: #28a745;
$warning-color: #f5a623;

$focused-input-color: #66afe9;
$default-input-border-color: $gray6;
$hovered-input-border-color: $gray3;
$input-placeholder-color: rgba(0, 0, 0, $dark-opacity-tertiary);

// Spacing
$md-spacing: 16px;
$xl-spacing: $md-spacing * 4;
$lg-spacing: $md-spacing * 2;
$sm-spacing: $md-spacing / 2;
$xs-spacing: $md-spacing / 4;

// Borders
$default-border: 1px solid $gray6;
$sm-border-radius: 4px;
$md-border-radius: 8px;
$lg-border-radius: 16px;
$xl-border-radius: 24px;
$max-border-radius: 1000px;

// Metrics
$content-width: 860px;
$md-content-width: 640px;
$sm-content-width: 480px;

// Measures
$measure-sm: 4em;
$measure-md: 8em;
$measure-lg: 16em;

$line-height-base: 1.5;
$border-radius-base: $lg-border-radius;
$input-padding-base: $xs-spacing $sm-spacing * 1.5;
$button-line-height: $line-height-base;
$input-line-height: $line-height-base;

// Shadows
$header-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.12);


$shadow-default-primary-color: rgba(60, 64, 67, 0.15);
$shadow-default-secondary-color: rgba(60, 64, 67, 0.149);

$shadow-default: 0 0px 2px 1px $shadow-default-primary-color,
0 0px 3px 1px $shadow-default-secondary-color;

$shadow-hover-default: 0 0px 3px 0 $shadow-default-primary-color,
0 0px 8px 3px $shadow-default-secondary-color;

$shadow-md: rgba(0, 0, 0, 0.1) 0 20px 60px -10px;
$shadow-sm: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
$gmail-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);

$previewFrameInterval: 834ms; // s - full-time = 12 * $previewFrameInterval

@keyframes playX {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 150%;
  }
}

@keyframes playY {
  from {
    background-position-y: 0;
  }
  to {
    background-position-y: 133.33333333333%;
  }
}
