@import "~open-color/open-color";

$link: #007bff;
$link-hover: #0056b3;

@import "~bulma/sass/utilities/all";
@import "~bulma/sass/base/minireset";
@import "~bulma/sass/base/generic";

@import "~bulma/sass/elements/container";
@import "~bulma/sass/elements/title";
@import "~bulma/sass/elements/button";
@import "~bulma/sass/form/all";
@import "~bulma/sass/components/modal";

@import "~@fortawesome/fontawesome-free/css/all.css";

html {
  background-color: #f0f0f0;
}

html, body {
  //overflow: hidden;
  height: 100vh;
}

.input {
  font-size: 1.25rem;
}

.container {
  max-width: 850px;
}
