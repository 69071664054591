@import "~bulma/sass/utilities/all";
@import "~open-color/open-color";

$default-border: 1px solid rgba(0, 0, 0, .13);

.inner {
  display: flex;
  height: inherit;
  flex-direction: row;

  @include mobile() {
    flex-direction: column;
  }
}

aside.panel {
  display: flex;
  width: 12rem;
  flex-direction: column;
  border-right: $default-border;

  @include mobile() {
    display: none;
  }

  .logo {
    padding: 1rem;
    display: flex;
    position: relative;
    user-select: none;

    img {
      height: 2.5rem;
    }

    span {
      position: absolute;
      left: 63px;
      top: 45px;
      font-size: .75rem;
      color: #6a88eb;
    }
  }

  .menu {

    ul {

      li {
        padding: .5rem .5rem .5rem 1rem;
        font-size: 1.2rem;
        margin: .5rem 0;

        &:hover,
        .active {
          cursor: pointer;
        }

        a {
          color: $oc-gray-6;
          display: flex;
          align-items: center;
          transition: color .2s ease-in;
        }

        i {
          margin-right: .5rem;
          color: $oc-gray-6;
          font-size: 80%;
          transition: color .2s ease-in;
        }
      }

      li {
        &.active {
          a, i {
            color: $oc-gray-9 !important;
          }
        }
        &:hover {
          a {
            color: $oc-gray-9 !important;
          }
        }
      }

    }
  }
}
