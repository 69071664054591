$dotWidth: 10px;
$dotHeight: 10px;
$dotRadius: $dotWidth/2;

$dotColor: #6a88eb;
$dotBgColor: $dotColor;
$dotBeforeColor: $dotColor;
$dotAfterColor: $dotColor;

$dotSpacing: $dotWidth + $dotWidth/2;

@mixin dot($width: $dotWidth, $height: $dotHeight, $radius: $dotRadius,$bgColor: $dotBgColor, $color: $dotColor ) {
  width: $width;
  height: $height;
  border-radius: $radius;
  background-color: $bgColor;
  color: $color;
}

@keyframes dotFlashing {
  0% {
    background-color: $dotColor;
  }

  50%,
  100% {
    background-color: lighten($dotColor, 20%);
  }
}

.dotInner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.dotFlashing {
  position: relative;

  @include dot;

  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: - $dotSpacing;

    @include dot($bgColor: $dotBeforeColor);

    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: $dotSpacing;

    @include dot($bgColor: $dotAfterColor);

    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
}
