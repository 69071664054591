$bluebell: #979fd0;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.donutLoaderWrapper {
  //width: 2rem;
  //height: 2rem;
  margin: 2rem;
  position: relative;
}

.donutLoader {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.3rem solid rgba($bluebell, 0.3);
  border-top-color: $bluebell;
  animation: spin 1.5s infinite linear;
}
